<template>

    <div>
        <div v-show="courses.count">
            <div class="row card-group-row" v-if="courses.count > 0">

                <div class="col-sm-6 col-md-4 col-lg-4 card-group-row__col" v-for="course in courses.data"
                     :key="'course' + course.id">

                    <CardCourse
                        :course="course"
                    />

                </div>
            </div>

            <div class="row" v-else>
                <div class="col-sm-12 card-group-row__col">

                    <div
                        class="card card-sm card--elevated p-relative o-hidden overlay overlay--primary-dodger-blue js-overlay card-group-row__card">

                        <router-link to="/mentorias/cadastrar">
                            <div class="card-img-top js-image" data-position="center" data-domfactory-upgraded="image">

                            </div>
                        </router-link>

                        <div class="card-body flex">
                            <div class="d-flex">
                                <div class="flex">
                                    <router-link to="/mentorias/cadastrar">
                                        <div class="card-title">
                                            Cadastre sua mentoria aqui
                                        </div>
                                    </router-link>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div class="row" v-if="courses.count > 0">
                <div class="col text-center mb-3">
                    <router-link to="/mentorias" class="text-link">
                        Ver mais
                    </router-link>
                </div>
            </div>

        </div>
        <div class="row" v-show="!courses.count">
            <div class="col text-center">
                <BSpinner/>
            </div>
        </div>
    </div>
</template>

<script>
import CardCourse from "@/views/dashboard/card-course.vue";

export default {
    components: {
        CardCourse
    },

    data() {
        return {
            loaded: false
        }
    },

    methods: {

        async loadList() {
            await this.$store.dispatch('api/getApi', 'mentorings?limit=3&status=1');
            setTimeout(() => {
                this.loaded = true;
            }, 500)
        },
    },

    mounted() {
        // chamada de busca de dados na store
        this.loadList();
    },

    computed: {
        courses: {
            get() {
                return this.$store.state.api.listAll ?? {};
            },
        },
    }
}
</script>

<style scoped>

.card-img-top.js-image {
    display: block;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
}

.mentoring-image {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
}
</style>
