<template>
    <Layout>

        <div class="row">
            <div class="col-md-12">
                <h1>Bem-vindo(a) à Lummefy!</h1>
            </div>
        </div>
        <div class="row" v-if="loaded">      
            <div class="col-md-12">

                <small class="ml-1">Siga o check-list abaixo para liberar a sua mentoria.</small>
                <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between mt-1" @click="$router.push('/meu-perfil')">
                        <span id="item-1" class="custom-link" :class="{ 'task-finished': firstTasks.profile}">Preencha seu perfil
                        </span>
                        <div style="margin-bottom: 15px;">

                            <input type="checkbox" class="form-check-input me-2 mt-0" disabled :checked="firstTasks.profile">
                            <i id="check-icon-1" class="fas fa-check-circle text-success d-none"></i>
                        </div>
                    </li>
                    <li class="list-group-item d-flex justify-content-between mt-1" @click="$router.push('/mentorias/cadastrar')">
                        <span id="item-2" class="custom-link" :class="{ 'task-finished': firstTasks.mentoring}">Crie sua primeira mentoria</span>
                        <div>
                            <input type="checkbox" class="form-check-input me-2 mt-0" disabled :checked="firstTasks.mentoring">
                            <i id="check-icon-2" class="fas fa-check-circle text-success d-none"></i>
                        </div>
                    </li>
                    <li class="list-group-item d-flex justify-content-between mt-1" @click="$router.push('/agenda')">
                        <span id="item-3" class="custom-link" :class="{ 'task-finished': firstTasks.schedule}">Configure a sua disponibilidade na Agenda</span>
                        <div>
                            <input type="checkbox" class="form-check-input me-2 mt-0" disabled :checked="firstTasks.schedule">
                            <i id="check-icon-3" class="fas fa-check-circle text-success d-none"></i>
                        </div>
                    </li>
                    <li class="list-group-item d-flex justify-content-between mt-1" @click="$router.push('/formularios')">
                        <span id="item-4" class="custom-link" :class="{ 'task-finished': firstTasks.form}">Crie seu primeiro formulário(opcional)</span>
                        <div>
                            <input type="checkbox" class="form-check-input me-2 mt-0" disabled :checked="firstTasks.form">
                            <i id="check-icon-4" class="fas fa-check-circle text-success d-none"></i>
                        </div>
                    </li>
                </ul>
            </div>              
        </div>
        <div class="row" v-else>
            
            <div class="col text-center">
                <span class="spinner spinner-border flex-shrink-0" id="spinnerTable" role="status">
                    <span class="visually-hidden"></span>
                </span>
            </div>
        
        </div>

        <CardsDashboard/>

        <Graphic/>

        <div class="row">

            <div class="col-12 mt-3">

                <div class="page-separator">
                    <div class="page-separator__text">Minhas Mentorias</div>
                </div>

                <ListCourses class="v-step-1"/>
            </div>

            <div class="col-12">

                <div class="page-separator">
                    <div class="page-separator__text v-step-2">To-do list</div>
                </div>

                <Activities/>

            </div>
        </div>
        <v-tour name="myTour" :steps=" steps " :options="tourOptions"></v-tour>
    </Layout>
</template>

<script>
import Layout from "@/components/layout/main.vue";
import ListCourses from "./list-courses.vue";
import CardsDashboard from "./cards-dashboard.vue";
import Graphic from "./graphic.vue";
import Activities from './activities.vue'

import http from "@/http";

export default {
    components: {
        Layout,
        ListCourses,
        CardsDashboard,
        Graphic,
        Activities
    },
    // name: 'my-tour',
    data() {
        return {
            loaded: false,
            firstTasks:{
                'profile': false,
                'mentoring': false,
                'schedule': false,
                'form': false,
            },
            steps: [
                {
                    target: '.v-step-0',
                    header: {
                        title: 'Início',
                    },
                    content: `Aqui estão os dados resumidos de sua mentoria`,
                    params: {
                        placement: 'top'
                    }
                },
                {
                    target: '.v-step-1',
                    header: {
                        title: 'Meus Cursos',
                    },
                    content: 'Aqui temos os seus cursos cadastrados',
                    params: {
                        placement: 'top'
                    }
                },
            ],
            tourOptions: {
                labels: {
                    buttonSkip: "Pular",
                    buttonPrevious: "Anterior",
                    buttonNext: "Próximo",
                    buttonStop: "Parar"
                }
            },
        }
    },

    mounted() {

        this.getFirstTasks();
        // if (this.$tours && this.$tours['myTour']) {
        //     this.$tours['myTour'].start();
        // }
    },
    methods: {
        getFirstTasks(){
            let self = this
            http.get('mentor/get-firsts-tasks')
                .then(response => {

                    self.firstTasks = response.data    
                    self.loaded = true                

                })
                .catch((e) => {
                    self.loaded = true
                    console.error(e);
                    // this.$notifyError('Erro ao excluir Módulo!');
                })
        }
    },
}
</script>

<style scoped>

    .task-finished {
        text-decoration: line-through;
    }

    .custom-link {
        cursor: pointer;
    }

</style>